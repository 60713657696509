import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"

export const query = graphql`
  query($code: String!) {
    tarjetasJson(code: { eq: $code }) {
      id
      name
      degree
      description
      email
      phone
    }
  }
`

const TarjetaPage = ({ data }) => {
  const tarjeta = data.tarjetasJson

  return (
    <Layout>
      <h1>{tarjeta.name}</h1>
      <h2>{tarjeta.degree}</h2>
    </Layout>
  )
}

export default TarjetaPage
